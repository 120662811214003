import React, { useEffect } from "react";
import Slider from "react-slick";
import "../../../node_modules/slick-carousel/slick/slick-theme.css";
import "../../../node_modules/slick-carousel/slick/slick.css";
import BackgroundOverlay from "../../components/Fragments/BackgroundOverlay";

import {
  background7,
  fabrication2,
  fabrication3,
  manpower1,
  manpower2,
  manpower3,
  manpower4,
  manufacturing2,
  manufacturing4,
  mapdpp,
  patria,
  pml,
  pmp,
  triatra,
  ut
} from "../../assets";

const Industries = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const data = "Bisnis Kami";
  const settings = {
    // dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };

  function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "none", background: "red" }}
        onClick={onClick}
      />
    );
  }

  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "none", background: "green" }}
        onClick={onClick}
      />
    );
  }

  return (
    <>
      <BackgroundOverlay content={data} gambar={background7} />

      <div className=" mx-auto text-center px-5 lg:w-[80%] lg:mb-24 lg:px-0">
        <p className="lg:font-normal lg:text-lg xl:text-2xl text-center">
          Dalam menjalankan bisnis, kami fokus pada dua sektor ketenagakerjaan yaitu penyedia tenaga kerja (Labour Supply), penyedia jasa fabrikasi, painting dan assembling serta perbaikan kendaraan alat berat (Job Supply). Di samping itu, untuk mengedepankan customer, kami juga mengembangkan bisnis pada component manufacturing dan sticker cutting yang diberikan kepada customer serta memperluas jejaring dengan memberikan jasa borongan project pada fabrikasi alat berat lainnya.
        </p>
      </div>

      <div
        className="grid mt-1 grid-cols-1 lg:grid-cols-2 mx-60 lg:px-32"
        data-aos="fade-in"
        data-aos-duration="1500"
      >
        <div className="p-3  w-full h-full  mx-auto">
          <Slider {...settings}>
            <img alt="12312" src={manufacturing2} />
            <img alt="12312" src={fabrication2} />
            <img alt="12312" src={fabrication3} />
            <img alt="12312" src={manufacturing4} />
          </Slider>

          <div className="text-center w-full">
            <p className="uppercase font-semibold lg:text-lg xl:text-2xl my-4 md:h-[76px] ">
              Job Supply
            </p>
            <p className="mb-4 xl:text-justify lg:text-sm xl:text-base">
              Selain proses manufaktur industri dan layanan manpower, kami juga
              menyediakan pekerjaan borongan dalam proses konstruksi bangunan
              ataupun fabrikasi kendaran alat berat.
            </p>
          </div>
        </div>

        <div className="p-3 w-full h-full  mx-auto">
          <Slider {...settings}>
            <img alt="12312" src={manpower1} />
            <img alt="12312" src={manpower2} />
            <img alt="12312" src={manpower3} />
            <img alt="12312" src={manpower4} />
          </Slider>
          <div className="text-center w-full">
            <p className="uppercase font-semibold lg:text-lg xl:text-2xl my-4 md:h-[76px]">
              Labour Supply
            </p>
            <p className="mb-4 xl:text-justify  lg:text-sm xl:text-base">
              Dalam berupaya meningkatkan SDM di dalam bisnis AHEMCE dan PATRIA
              Group, kami selalu berusaha untuk dapat memberikan layanan terbaik
              dalam proses manpower solution di bidang Welder, Assembler, dan
              Painter untuk dapat menjadi solusi layanan manpower terbaik di
              Indonesia
            </p>
          </div>
        </div>
      </div>

      <hr className="mt-10 mb-10" />
      <div className="text-center w-full h-full lg:mt-2 overflow-hidden">
        <h2
          data-aos="fade-in"
          data-aos-duration="2500"
          className="mb-8 lg:mb-16 text-3xl font-bold tracking-tight leading-tight text-center text-gray-900 dark:text-white md:text-4xl"
        >
          Our Location
        </h2>
        <img
          data-aos="fade-up"
          data-aos-duration="1500"
          className="w-full mt-5 my-10"
          src={mapdpp}
          alt="branch"
        />
      </div>

      <hr className="mt-10 mb-10" />
      <section className="bg-white dark:bg-gray-900 overflow-hidden">
        <div className="py-8 lg:py-10 mx-auto max-w-screen-xl px-4">
          <h2
            data-aos="fade-in"
            data-aos-duration="2500"
            className="mb-8 lg:mb-16 text-3xl font-bold tracking-tight leading-tight text-center text-gray-900 dark:text-white md:text-4xl"
          >
            Our Clients
          </h2>
          <div
            data-aos="fade-up"
            data-aos-duration="1500"
            className="flex flex-col gap-8 text-gray-500 sm:flex-col md:flex-row lg:flex-row dark:text-gray-400 text-center"
          >
            <div className="basis-1/2">
              <img
                className="flex justify-center items-center sm:size-0.5"
                src={patria}
                alt="patria"
              />
            </div>
            <div className="basis-1/2">
              <img
                className="flex justify-center items-center"
                src={ut}
                alt="ut"
              />
            </div>
            <div className="basis-1/2">

              <img
                className="flex justify-center items-center mt-3"
                src={triatra}
                alt="triatra"
              />
            </div>
            <div className="basis-1/2">

              <img
                className="flex justify-center items-center"
                src={pmp}
                alt="patria"
              />
            </div>
            <div className="basis-1/2">

              <img
                className="flex justify-center items-center"
                src={pml}
                alt="patria"
              />
            </div>
          </div>
        </div>
      </section>


    </>
  );
};

export default Industries;
